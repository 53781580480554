.layOut {
  min-height: 100vh;
}

/* .layoutContent{

} */
.avatar{
   margin-right: 2%;
   float: right;
}

.logo {
  text-align: center;
  color: white;
}

.header {
  padding: 0;
  background: rgb(255, 255, 255);
}

.content {
  margin: 0 16px;
}

.contentItem {
  padding: 24px;
  /* min-height: 100vh; */
  background: rgb(255, 255, 255);
}

.search{
    padding: 24px;
    margin-bottom: 2%;
    background: rgb(255, 255, 255);
}

.breadCrumb {
  margin: 16px 0;
}

.boderrow{
  border-bottom: 1px solid rgba(228, 215, 215, 1);
  padding-bottom: 1%;
}
